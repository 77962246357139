import { createAsyncThunk } from '@reduxjs/toolkit';
import ApiClient from '../../ApiClient';

export interface ILogin {
  personName: string;
  address: string;
  profileImage: string;
  description: string;
  isMarried: boolean;
  phoneNumber: string;
  roleList: string[];
}

const loginThunk = createAsyncThunk<
  any,
  {
    userName: string;
    password: string;
  }
>('auth/login', async (user, thunkAPI) => {
  try {
    const request = {
      userName: user.userName,
      password: user.password,
    };

    const response = await ApiClient.postJsonData(`/Account/authenticate`, {}, request);

    const token = {
      userName: user.userName,

      password: user.password,
      token: response.data.data.jwToken,
      jwTokenExpires: response.data.data.jwTokenExpires,
      // type: response.data.roleList,
    };
    localStorage.setItem('jwTokenExpires', JSON.stringify(token.jwTokenExpires));
    localStorage.setItem('token', JSON.stringify(token.token));
    return response;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.errors);
  }
});

const changePasswordThunk = createAsyncThunk<
  any,
  {
    userName: string;
    currentPassword: string;
    newPassword: string;
  }
>('auth/changePassword', async (user, thunkAPI) => {
  try {
    const request = {
      userName: user.userName,
      currentPassword: user.currentPassword,
      newPassword: user.newPassword,
    };

    const response = await ApiClient.postJsonData(`/Account/change-password`, {}, request);

    return response;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.errors);
  }
});

export { loginThunk, changePasswordThunk };
