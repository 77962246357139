export const path = {
  // main path
  ROOT_PATH: '/',
  NOT_FOUND_PATH: 'not-found',
  CATEGORY_PATH: 'category',
  DETAIL_PATH: 'detail',
  EVENT_PATH: 'event',
  RETAILER_PATH: 'retailer',

  // auth path
  LOGIN_PATH: 'login',

  // admin path
  ADMIN_PATH: 'admin',
  ADMIN_PATH_HOME: 'dashboard',

  // Footer
  INTRODUCTION_PATH: 'introduction',
  DISCLOSURE_PATH: 'disclosure',
  DISCLAIMER_PATH: 'disclaimer',
  CAREERS_PATH: 'careers',
};

export const ACTION = {
  BRAND: 'brand',
  CATEGORY: 'category',
  EVENT: 'event',
  RETAILER: 'retailer',
  REVIEWS: 'review',
  DEAL: 'deal',
  DEAL_IMAGE: 'deal-image',
};

export const LIST_HEADER_TABLE_CATEGORY = [
  {
    id: '1',
    label: 'Code',
    dataIndex: 'code ',
    type: 'input',
    required: true,
  },
  {
    id: '2',
    label: 'Name',
    dataIndex: 'name ',
    type: 'input',
    required: true,
  },
  // {
  //   id: '3',
  //   label: 'Level',
  //   dataIndex: 'level ',
  //   type: 'input',
  // },
  // {
  //   id: '4',
  //   label: 'Parent',
  //   dataIndex: 'parentId',
  //   type: 'select',
  // },
  {
    id: '5',
    label: 'Image Path',
    dataIndex: 'imagePath',
    type: 'upload',
    required: true,
  },
  {
    id: '7',
    label: 'Description',
    dataIndex: 'description ',
    type: 'textarea',
  },
];

export const LIST_HEADER_TABLE_DEAL = [
  {
    id: '1',
    label: 'Title',
    dataIndex: 'title',
    type: 'input',
    required: true,
  },
  {
    id: '2',
    label: 'Shipping Info',
    dataIndex: 'shippingInfo',
    type: 'input',
  },
  {
    id: '3',
    label: 'URL',
    dataIndex: 'dealURL',
    type: 'input',
    required: true,
  },
  {
    id: '4',
    label: 'OriginalPrice',
    dataIndex: 'originalPrice',
    type: 'number',
  },
  {
    id: '5',
    label: 'Discount',
    dataIndex: 'discount',
    type: 'number',
  },
  {
    id: '6',
    label: 'Start Date',
    dataIndex: 'startDate',
    type: 'date',
  },
  {
    id: '7',
    label: 'End Date',
    dataIndex: 'endDate',
    type: 'date',
  },

  {
    id: '9',
    label: 'Retailer',
    dataIndex: 'retailerId',
    type: 'select',
  },
  {
    id: '10',
    label: 'Event',
    dataIndex: 'eventId',
    type: 'select',
  },
  {
    id: '11',
    label: 'Category',
    dataIndex: 'categoryId',
    type: 'select',
    required: true,
  },
 

  {
    id: '12',
    type: 'checkbox',
    children: [
      {
        label: 'Availability',
        dataIndex: 'availability',
      },
      {
        label: 'Hot',
        dataIndex: 'isHot',
      },
      {
        label: 'Slide',
        dataIndex: 'isSlide',
      },
    ],
  },
  
  {
    id: '16',
    label: 'Image Path',
    dataIndex: 'imagePath',
    type: 'upload',
  },
  {
    id: '15',
    label: 'Description',
    dataIndex: 'description',
    type: 'ckeditor',
    required: true,
  },

];

export const LIST_HEADER_TABLE_BRAND = [
  {
    id: '1',
    label: 'Code',
    dataIndex: 'code ',
    type: 'input',
    required: true,
  },
  {
    id: '2',
    label: 'Name',
    dataIndex: 'name ',
    type: 'input',
    required: true,
  },
  {
    id: '3',
    label: 'Website',
    dataIndex: 'website ',
    type: 'input',
  },
  {
    id: '4',
    label: 'Address',
    dataIndex: 'address ',
    type: 'input',
  },
  {
    id: '5',
    label: 'Phone',
    dataIndex: 'phone ',
    type: 'input',
  },
  {
    id: '6',
    label: 'Email',
    dataIndex: 'email ',
    type: 'input',
  },
  {
    id: '7',
    label: 'Description',
    dataIndex: 'description ',
    type: 'textarea',
  },
];
export const LIST_HEADER_TABLE_RETAILER = [
  {
    id: '1',
    label: 'Code',
    dataIndex: 'code ',
    type: 'input',
    required: true,
  },
  {
    id: '2',
    label: 'Name',
    dataIndex: 'name ',
    type: 'input',
    required: true,
  },
  {
    id: '3',
    label: 'Website',
    dataIndex: 'website',
    type: 'input',
  },
  {
    id: '4',
    label: 'Address',
    dataIndex: 'address',
    type: 'input',
  },
  {
    id: '5',
    label: 'Phone',
    dataIndex: 'phone',
    type: 'input',
  },
  {
    id: '6',
    label: 'Email',
    dataIndex: 'email',
    type: 'input',
  },
  {
    id: '7',
    label: 'Image Path',
    dataIndex: 'imagePath',
    type: 'upload',
    required: true,
  },
  {
    id: '8',
    label: 'Description',
    dataIndex: 'description ',
    type: 'textarea',
  },
];
export const LIST_HEADER_TABLE_EVENT = [
  {
    id: '1',
    label: 'Code',
    dataIndex: 'code',
    type: 'input',
    required: true,
  },
  {
    id: '2',
    label: 'Name',
    dataIndex: 'name ',
    type: 'input',
    required: true,
  },

  {
    id: '3',
    label: 'Start Date',
    dataIndex: 'startDate',
    type: 'date',
    required: true,
  },
  {
    id: '4',
    label: 'End Date',
    dataIndex: 'endDate',
    type: 'date',
    required: true,
  },
  {
    id: '7',
    label: 'Image Path',
    dataIndex: 'imagePath',
    type: 'upload',
    required: true,
  },
  {
    id: '8',
    label: 'Description',
    dataIndex: 'description ',
    type: 'textarea',
  },
];
